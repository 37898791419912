import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import MyToast from "../components/UI/toast/MyToast";
import RegisterForm from "../components/Auth/RegisterForm";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { Link } from "react-router-dom";
import { ORGANIZATION_NAME } from "../config";


const Register = () => {
  const { generalError, setGeneralError, closeAlert } = useContext(AuthContext);

  useEffect(() => {
    document.title = "Регистрация | " + ORGANIZATION_NAME
  }, [])

  return (
    <>
      <MyToast error={generalError} closeAlert={closeAlert} />
      <div className="height-need">
        <div className="main2">
          <div className="two-sides-flex centered-two-sides">
            <div className="left-login-block">
              <RegisterForm />
              <div className="register-link">
                Уже есть аккаунт? <Link to="/login">Войти</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
